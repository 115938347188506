.header__container {
    text-align: center;
    height: 90%;
    position: relative;
}

/* ================== CTA ================== */
.cta { 
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.disabled-link {
    pointer-events: none;
}

/* ================== HEADER SOCIALS ================== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ================== ME ================== */
.me {
    background: transparent;
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    overflow: hidden;
    padding: 3rem 1.5rem 1.5rem 1.5rem;
}

/* ================== SCROLL DOWN ================== */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ================== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    header {
        height: 28vh;
    }
    .scroll__down {
        position: absolute;
        right: -6rem;
        bottom: 5rem;
        transform: rotate(90deg);
        font-weight: 300;
        font-size: 0.9rem;
    }
}

/* ================== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .header__socials, .scroll__down {
        display: none;
    }
}
